<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
    {{ data.description }}
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>取消</button>
    <button mat-flat-button [mat-dialog-close]="true" color="primary">
        确定
    </button>
</mat-dialog-actions>
