@if (showPrevArrow) {
  <button
    (click)="previous()"
    aria-hidden="true"
    tabindex="-1"
    class="carousel-nav carousel-nav-prev"
    mat-mini-fab
    aria-label="previous"
  >
    <mat-icon>navigate_before</mat-icon>
  </button>
}
<div
  #contentWrapper
  (keyup)="onKeydown($event)"
  class="carousel-content-wrapper"
  role="region"
>
  <div class="carousel-content" role="list" tabindex="0" #list>
    <ng-content></ng-content>
  </div>
</div>
@if (showNextArrow) {
  <button
    (click)="next()"
    aria-hidden="true"
    tabindex="-1"
    class="carousel-nav carousel-nav-next"
    mat-mini-fab
    aria-label="next"
  >
    <mat-icon>navigate_next</mat-icon>
  </button>
}
