<div class="hoteler-error-wrap">
    <div class="hoteler-error-code">{{ code() }}</div>
    @if (title()) {
        <div class="hoteler-error-title">{{ title() }}</div>
    }
    @if (message()) {
        <div class="hoteler-error-message">{{ message() }}</div>
    }
    <div>
        <a mat-raised-button color="primary" routerLink="/login"
            >前往登录页面</a
        >
    </div>
</div>
